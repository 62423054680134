import parse from "html-react-parser";
import React from "react";

const IIPCollapse = (props) => {
  const {
    content,
    boolcollapse,
    prefixUrl,
    id,
    fontSize,
    datatestid,
    spacing,
  } = props;
  return (
    <>
      <div className={`mm-collapse ${spacing}`}>
        <button
          className="btn-collapse"
          type="button"
          data-toggle="collapse"
          data-target={`#IIPCollapse-${id}`}
          aria-expanded="false"
          aria-controls="IIPCollapse"
        >
          <span>
            <span className={fontSize} data-testid="dropdown-title">
              {content.text}
            </span>
          </span>
          <span className="indicator"></span>
        </button>
        <div className="collapse content" id={`IIPCollapse-${id}`}>
          <ul className="mm-actions-list">
            {!boolcollapse &&
              content.list.map((dropdown, index) => {
                return (
                  <li key={index}>
                    <a href={dropdown.url} target="_blank" rel="noreferrer">
                      {parse(dropdown.type)}
                    </a>
                  </li>
                );
              })}
            {boolcollapse &&
              !prefixUrl.includes("boli") &&
              content.list.map((dropdown, index) => {
                return (
                  <li key={index} data-testid="iip-links">
                    <span>
                      {dropdown.type}&nbsp; - &nbsp;
                      <a
                        href={prefixUrl + dropdown.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u>{dropdown.seriestype}</u>
                      </a>
                      { dropdown.factSheetUrl &&
                      <> 
                        |
                        <a
                          href={prefixUrl + dropdown.factSheetUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <u> {dropdown.factSheet}</u>
                        </a>
                        |
                        <a
                          href={prefixUrl + dropdown.fundPerformanceUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <u> {dropdown.fundPerformance} </u>
                        </a>
                      </>
                      }
                    </span>
                  </li>
                );
              })}
            {boolcollapse &&
              prefixUrl.includes("boli") &&
              content.list.map((dropdown, index) => {
                return (
                  <li key={index} data-testid={datatestid}>
                    <a
                      href={prefixUrl + dropdown.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {parse(dropdown.type)}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default IIPCollapse;
